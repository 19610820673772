const ButtonsGender = () => {
  return (
    <section className="buttons-genders">
      <a href="https://www.decathlon.com.br/pesquisa/?q=fitness&2400=2416&facets=2400&sort=score_desc&page=1">
        feminino
      </a>
      <a href="https://www.decathlon.com.br/pesquisa/?q=fitness&2400=2411&facets=2400&sort=score_desc&page=1">
        masculino
      </a>
    </section>
  )
}

export default ButtonsGender
