import BannerFull from './BannerFull'
import ButtonsGender from './ButtonsGender'
import Categorias from './Categorias'
import ChoiceLegging from './ChoiceLegging'
import './styles.scss'
import ThreeCards from './ThreeCards'
import TwoCards from './TwoCards'
import VideoSection from './VideoSection'

const FitnessCardioComponent = () => {
  return (
    <>
      <main className="main-fitness-cardio">
        <BannerFull />
        <ButtonsGender />
        <ChoiceLegging />
        <ThreeCards />
        <TwoCards />
        <Categorias />
        <VideoSection />
      </main>
    </>
  )
}

export default FitnessCardioComponent
