const ThreeCards = () => {
  return (
    <section className="three-cards">
      <a href="https://www.decathlon.com.br/collection?q=662&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-camisetas-fitness-cardio.png"
          alt="Camisetas Fitness"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=663&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-croppeds-fitness-cardio.png"
          alt="Croppeds Fitness"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=664&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-shorts-fitness-cardio.png"
          alt="shorts Fitness"
        />
      </a>
    </section>
  )
}

export default ThreeCards
