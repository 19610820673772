const ChoiceLegging = () => {
  return (
    <section className="choice-legging">
      <a href="https://www.decathlon.com.br/collection?q=661&O=OrderByTopSaleDESC#1">
        <img
          className="desk"
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-leggings-desk.png"
          alt="Banner Leggings"
        />
        <img
          className="mob"
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-leggings-mob.png"
          alt="Banner Leggings"
        />
      </a>
    </section>
  )
}

export default ChoiceLegging
