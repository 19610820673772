const Categorias = () => {
  return (
    <section className="categorias">
      <div className="wrapper-title">
        <span>mais</span>
        <span>categorias</span>
      </div>
      <a href="https://www.decathlon.com.br/collection?q=671&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/picto-saude-fitness-cardio.png"
          alt="Picto Saude Leggings"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=672&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/picto-bolsas-fitness-cardio.png"
          alt="Picto Bolsas Leggings"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=674&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/picto-ECO-fitness-cardio.png"
          alt="picto ECO Leggings"
        />
      </a>
    </section>
  )
}

export default Categorias
