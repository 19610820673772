const BannerFull = () => {
  return (
    <section className="banner-full">
      <a href="https://www.decathlon.com.br/collection?q=669&O=OrderByTopSaleDESC#1">
        <img
          className="desk"
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-full-fitness-cardio.png"
          alt="Banner Fitness Cardio"
        />
        <img
          className="mob"
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-full-fitness-cardio-mob.png"
          alt="Banner Fitness Cardio"
        />
      </a>
    </section>
  )
}

export default BannerFull
