const TwoCards = () => {
  return (
    <section className="two-cards">
      <a href="https://www.decathlon.com.br/collection?q=666&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-equipas-desk-fitness-cardio.png"
          alt="Equipas Fitness"
        />
      </a>
      <a href="https://www.decathlon.com.br/collection?q=665&O=OrderByTopSaleDESC#1">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/banner-masculino-desk-fitness-cardio.png"
          alt="Masculino Fitness"
        />
      </a>
    </section>
  )
}

export default TwoCards
