const VideoSection = () => {
  return (
    <section className="video">
      <iframe
        className="desk"
        width="100%"
        height="700"
        src="https://www.youtube.com/embed/hBhHTwAh-AU"
        title="video campanha fitness cardio"
        frame-border="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
      <iframe
        className="mob"
        width="100%"
        height="268"
        src="https://www.youtube.com/embed/hBhHTwAh-AU"
        frame-border="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        title="video fitness cardio"
      />
    </section>
  )
}

export default VideoSection
